// Styles
import '@mdi/font/css/materialdesignicons.css'

import colors from 'vuetify/lib/util/colors'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  {
    theme: {
      defaultTheme: localStorage.getItem('lastTheme') || 'light',
      themes: {
        light: {
          primary: colors.blue,
          secondary: colors.orange,
        },
        // dark: {
        //     dark: true,
        //     primary: colors.blue,
        //     secondary: colors.orange,
        // },
      }
    },
    // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  })
