<template>
  <div class="row align-center justify-center">
    <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12 col-12">
      <div class="row align-center justify-space-around">
        <div class="col-2">
          <router-link
            to="/"
            class="mr-5"
            style="text-decoration: none; color: inherit"
          >
            <img
              v-if="$q.dark.isActive"
              class="mr-5"
              :src="require('@/assets/logo-white.svg')"
              style="max-width: 60px"
            />
            <img
              v-else
              :src="require('@/assets/logo-black.svg')"
              class="mr-5"
              style="max-width: 60px"
            />
          </router-link>
        </div>

        <div class="col-2">
          <router-link
            to="/topups"
            class="mr-5"
            style="text-decoration: none; color: inherit"
          >
            <span>Topups</span>
          </router-link>
        </div>

        <div class="col-2">
          <router-link
            to="/gifts"
            class="mr-5"
            style="text-decoration: none; color: inherit"
          >
            <span>Giftcards</span>
          </router-link>
        </div>

        <div class="col-2">
          <q-btn
            flat
            @click="toggleDarkMode()"
            :icon="$q.dark.isActive ? 'light_mode' : 'bedtime'"
          >
          </q-btn>
        </div>
      </div>
    </div>        
  </div>
</template>

<script>
import { useQuasar } from "quasar";
export default {
  name: "NavBar",
  setup() {
    const $q = useQuasar();
    return {
      // $q,
      toggleDarkMode: function () {
        $q.dark.toggle();
      },
    };
  },
};
</script>

<style>
/* span {
    font-weight: bold;
    font-size: 20px;;
} */
</style>