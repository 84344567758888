<template>
  <q-layout view="hHh lpR fFf">
    <nav-bar/>
    <q-page-container style="min-height: 80vh">
      <router-view />
    </q-page-container>
    <foot-bar/>
  </q-layout>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FootBar from '@/components/FootBar.vue';
export default {
  name: "App",
  components:{NavBar, FootBar}
};
</script>

<style>
a {
  text-decoration: none !important;
}
</style>