import { createWebHistory, createRouter } from "vue-router";

const index = () => import("@/views/index.vue");
const who = () => import("@/views/who.vue");
const gifts = () => import("@/views/gifts.vue");
const gift = () => import("@/views/gift.vue");
const topups = () => import("@/views/topups.vue");
const sol_payment = () => import("@/views/solana-payment.vue");
const evm_payment = () => import("@/views/evm-payment.vue");

const routes = [
    { path: '/', name: 'index', component: index },
    { path: '/who', name: 'who', component: who},
    { path: '/gifts', name: 'gifts', component: gifts},
    { path: '/gifts/:id', name: 'gift', component: gift},
    { path: '/topups', name: 'topups', component: topups},
    { path: '/solana-payment', name: 'solana-payment', component: sol_payment},
    { path: '/evm-payment', name: 'evm-payment', component: evm_payment},
    { path: '/:pathMatch(.*)*', component: index },
    { path: '/:pathMatch(.*)', component: index },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;