<template>
  <div class="row align-center justify-space-around">
    <div class="col-1 d-flex align-center">
      <router-link to="/who" class="mr-6 ">
        <q-icon name="description" size="sm"></q-icon>
      </router-link>
      <a
        href="https://twitter.com/LiberaStore"
        target="_blank"
        rel="noopener noreferrer"
        class="mr-6"
      >
        <q-icon name="chat_bubble" size="sm"></q-icon>
      </a>
      
      <a
        href="mailto:rok_mt@proton.me"
        target="_blank"
        rel="noopener noreferrer"
      >
        <q-icon name="email" size="sm"></q-icon>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FootBar",
};
</script>

<style scoped>
a {
  text-decoration: none;
}
a:link,
a:visited {
  color: inherit;
}
a:hover {
  color: gray;
}
</style>