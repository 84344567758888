import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { loadFonts } from './plugins/webfontloader'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import VueMixpanel from 'vue-mixpanel'

loadFonts()

const analytics_id = process.env.VUE_APP_ANALYTICS_ID
createApp(App).use(Quasar, quasarUserOptions)
  .use(vuetify)
  .use(router)
  .use(VueMixpanel, {
    token: analytics_id,
    config: {
      debug: process.env.NODE_ENV != "production"
    }
  })
  .mount('#app')

